<i18n src="@/locales/basal_implant_operation.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="basal-implant-ope">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <p>
        {{ $t('message_1_1') }}
      </p>
      <p class="mt-1">
        <span class="icon-text">
          <span class="icon">
            <i class="mdi mdi-arrow-right-thick" />
          </span>
          <a
            href="/contact"
            class="is-info"
          >{{ $t('message_1_2') }}</a></span>
      </p>
    </b-message>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{ $t('article_1_1') }}
        </p>
        <p>
          {{ $t('article_1_2') }}
        </p>
        <p>
          {{ $t('article_1_3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <section class="section">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <figure>
            <video
              src="https://trate.com/wp-content/uploads/2018/06/Basal-Excellent-protection-from-inflammation-V1-320x320.mp4"
              poster="https://trate.com/wp-content/uploads/2018/06/Basal-Excellent-protection-from-inflammation-V1-320x320.mp4"
              autoplay=""
              loop="loop"
            />
          </figure>
        </div>
        <div class="column">
          <h3 class="has-text-primary is-size-4">
            {{ $t('h3_title_2') }}
          </h3>
          <p>
            {{ $t('article_2') }}
          </p>
        </div>
      </div>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_3') }}</ImanH3>
        <p>
          {{ $t('article_3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>{{ $t('h3_title_4') }}</ImanH3>
        <p>
          {{ $t('article_4') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[3]">
        <ImanH3>{{ $t('h3_title_5') }}</ImanH3>
        <p>
          {{ $t('article_5_1') }}
        </p>
        <p>
          <span v-html="$t('article_5_2')" />
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[4]">
        <ImanH3>{{ $t('h3_title_6') }}</ImanH3>
        <p>
          {{ $t('article_6_1') }}
        </p>
        <p>
          <span v-html="$t('article_6_2')" />
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[5]">
        <p>
          <span v-html="$t('article_7')" />
        </p>
        <ImanMoreInfo :path="$t('more_info_path_3')">
          {{ $t('more_info_3') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[6]">
        <ImanH3>{{ $t('h3_title_7') }}</ImanH3>
        <p>
          {{ $t('article_8') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_4')">
          {{ $t('more_info_4') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[7]">
        <ImanH3>{{ $t('h3_title_8') }}</ImanH3>
        <p>
          {{ $t('article_9_1') }}
        </p>
        <p>
          {{ $t('article_9_2') }}
        </p>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_9') }}
        </h3>
        <p>
          {{ $t('article_10') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_5')">
          {{ $t('more_info_5') }}
        </ImanMoreInfo>
      </ImanArticle>
      <div class="columns is-centered">
        <div class="column is-half">
          <h3 class="has-text-primary is-size-4">
            {{ $t('h3_title_10') }}
          </h3>
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Operation',
    components: {
      ImanMoreInfo,
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        articlesCardContentId: [83, 69, 53, 23],
        img: [
          {
            path: 'dental-implant/basal-implant/operation/consultation-dentiste-implant-basal.jpg',
            position: 'right',
            alt: 'consultation d\'un dentiste pour la pose d\'implant basal'
          },
          {
            path: 'dental-implant/basal-implant/operation/anesthesie-dentaire.jpg',
            position: 'right',
            alt: 'Pose d\'implants dentaires basal sous anesthésie générale'
          },
          {
            path: 'dental-implant/basal-implant/operation/implant-basal-derniere-generation.jpg',
            position: 'right',
            alt: 'perforation et forage de l\'os pour la pose d\'un implant dentaire basal'
          },
          {
            path: 'dental-implant/basal-implant/operation/basal_implant_bcs_ihde_procedure.jpg',
            position: 'right',
            alt: 'procédure implant bcs'
          },
          {
            path: 'dental-implant/basal-implant/operation/fabrication-prothese-dentaire-v2.jpg',
            position: 'right',
            alt: 'fabrication prothèse dentaire'
          },
          {
            path: 'dental-implant/basal-implant/operation/modelisation-empreinte-3D-denalLab.jpg',
            position: 'right',
            alt: 'modélisation empreinte 3D'
          },
          {
            path: 'dental-implant/basal-implant/operation/consultation-dentiste-implant-basal.jpg',
            position: 'right',
            alt: 'consultation dentiste pour l\'implant basal'
          },
          {
            path: 'dental-implant/basal-implant/operation/operation-implant-basal.jpg',
            position: 'right',
            alt: 'opération implant basal'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/I7OTFxghFig'
        }
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.2.2.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/operation/H1-operation-implant-basal-d.jpeg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/operation/H1-operation-implant-basal-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/operation/H1-operation-implant-basal-m.jpg");
    }
  }
</style>
